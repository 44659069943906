import { extendTheme, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from "@chakra-ui/theme-tools";

const config = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    // Define custom color schemes
    highImpact: {
      100: "#E41E202E",
      200: '#E41E20'
    },
    moderateImpact: {
      100: '#e4811e2e',
      200: '#e4811e'
    },
    lowImpact: {
      100: '#22e41e2e',
      200: '#22e41e'
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode('white', 'black')(props),
      }
    })
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '0', // This removes the border radius from all buttons
      },
    },
    Progress: {
      filledTrack: {
        bg: (props: any) => props.colorScheme,
      }
    },
    Heading: {
      baseStyle: {
        fontFamily: "monospace"
      }
    }
  }
}

const theme = extendTheme(config)

export default theme
