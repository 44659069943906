import React from 'react';
import { PieChart, Pie, Tooltip, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Text } from "@chakra-ui/react";
import { Text as ReChartText } from 'recharts';
import { useNavigate } from 'react-router-dom';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  let newName = ""

  if (newName === "" && Object.hasOwn(props.payload.payload, "remote_ip")) {
    newName = props.payload.payload.remote_ip
  } else if (newName === "" && Object.hasOwn(props.payload.payload, "tags")) {
    newName = props.payload.payload.tags
  } else {
    newName = payload.name
  }

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {""}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={"rgba(228, 30, 30, 1)"}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={"rgba(228, 30, 30, 1)"}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={"rgba(228, 30, 30, 1)"} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={"rgba(228, 30, 30, 1)"} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${newName || name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {"Attacks: " + value}
      </text>
    </g>
  );
};


export default function CustomPieChart(props: any) {
  const navigate = useNavigate();

  if (props.data.length === 0)
    return <></>

  const countKey = (() => {
    if (Object.hasOwn(props.data[0], "tag_count") && Object.hasOwn(props.data[0], "tags")) {
      return { countKey: "tag_count", nameKey: "tags" }
    } else if (Object.hasOwn(props.data[0], "tags") && Object.hasOwn(props.data[0], "request_count")) {
      return { countKey: "request_count", nameKey: "tags" }
    } else if (Object.hasOwn(props.data[0], "cve") && Object.hasOwn(props.data[0], "request_count")) {
      return { countKey: "request_count", nameKey: "cve" }
    } else if (Object.hasOwn(props.data[0], "cve_count")) {
      return { countKey: "cve_count", nameKey: "cve" }
    } else if (Object.hasOwn(props.data[0], "remote_ip")) {
      return { countKey: "count", nameKey: "IP" }
    } else if (Object.hasOwn(props.data[0], "tags")) {
      return { countKey: "count", nameKey: "Tags" }
    } else if (Object.hasOwn(props.data[0], "cve")) {
      return { countKey: "count", nameKey: "cve" }
    }

    return undefined;
  })()

  if (!countKey) return <></>;

  const handlePieClick = (data: any, index: number) => {
    const clickedBar = data.payload.payload;
    if (Object.hasOwn(clickedBar, "tags")) {
      navigate(`/search/${encodeURIComponent(clickedBar.tags)}`);
    } else if (Object.hasOwn(clickedBar, "cve")) {
      navigate(`/cve/${encodeURIComponent(clickedBar.cve)}`);
    } else if (Object.hasOwn(clickedBar, "remote_ip")) {
      navigate(`/search/${encodeURIComponent(clickedBar.remote_ip)}`);
    }
  };

  const sortedData = props.data.sort((a: any, b: any) => a[countKey.countKey] - b[countKey.countKey]);
  return (
    <div className={"chartWrapper"} style={props.style}>
      <Text fontSize="md" sx={{ padding: "var(--chakra-space-4)", paddingBottom: "0px", color: "#5b5b5b" }}>{props.heading}</Text>
      <ResponsiveContainer className={"chartDisplay"} width="100%" aspect={1}>
        <PieChart>
          <Pie
            data={sortedData}
            dataKey={countKey.countKey}
            nameKey={countKey.nameKey}
            activeShape={renderActiveShape}
            cx="50%"
            cy="50%"
            outerRadius={"54%"}
            innerRadius={"30%"}
            stroke={"rgb("+228*0.72+", 30, 32)"}
            fill="red"
            onClick={handlePieClick}
          // label={(entry) => entry[countKey.nameKey]}
          >
            {sortedData.map((entry: any, index: number) => {
              const maxCount = Math.max(...sortedData.map((o: any) => o[countKey.countKey]));
              const opacity = (entry[countKey.countKey] / maxCount).toFixed(3);
              return <Cell key={`cell-${index}`} fill={"rgba(228, 30, 32," + opacity + ")"} />
            })}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

const CustomTooltip = (props: any) => {
  if (props.active && props.payload && props.payload.length) {
    if (Object.hasOwn(props.payload[0].payload, "remote_ip")) {
      return <></>
      return (
        <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
          <p>{`IP: ${props.payload[0].payload.remote_ip}`}</p>
          <p>{`Attacks: ${props.payload[0].payload.count}`}</p>
        </div>
      );
    } else if (Object.hasOwn(props.payload[0].payload, "tags")) {
      return <></>
      return (
        <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
          <p>{`Tag: ${props.payload[0].payload.tags}`}</p>
          <p>{`Attacks: ${props.payload[0].payload.count}`}</p>
        </div>
      );
    }
    return <></>
    return (
      <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
        <p>{`Tag: ${props.payload[0].name}`}</p>
        <p>{`Attacks: ${props.payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};