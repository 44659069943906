import axios from 'axios';
import { supabase } from './supabase';

export const callApiProxy = async (endpoint: any, method: any, data: any) => {
    const session = supabase.auth.getSession();
    try {
        const accessToken = (await session).data.session?.access_token;
        const response = await axios({
            method,
            url: `${process.env.REACT_APP_PROXY}/api/${endpoint}`, // Replace with your proxy API endpoint
            data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error calling proxy API:', error);
        throw error;
    }
};