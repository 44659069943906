import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { useRef } from "react";
import "../assets/styles/modal.css";

export default function Signup(props: any) {
    const initialRef = useRef(null)

    return (
        <Modal isOpen={props.open} initialFocusRef={initialRef} onClose={() => { props.setOpen(false) }} isCentered={true}>
            <ModalOverlay />
            <ModalContent className="modal-content">
                <ModalHeader>Sign Up</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text fontWeight='bold' mb='1rem'>
                        Please contact us at sales@alphatechs.al
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={() => { props.setOpen(false) }}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}