import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import processApiProxy from "../api/proxessApiProxy";
import { Accordion, Flex, Heading, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Menu, MenuItem, MenuButton, Button, MenuList, Progress, useToast } from "@chakra-ui/react";
import "../assets/styles/cve.css";
import CVEBg from "../assets/cve-bg.png";
import { ImNewTab } from "react-icons/im";
import CustomPieChart from "../components/CustomPieChart";
import { callApiProxy } from "../api/callApiProxy";
import countByKey, { getTagTimes, groupAndCountByDay } from "../utils/countByKey";
import CustomTreeMap from "../components/CustomTreeMap";
import CustomTable from "../components/CustomTable";
import CustomAccordion from "../components/CustomAccordionItem";
import CustomAccordionItem from "../components/CustomAccordionItem";
import { Area, AreaChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "../assets/styles/search.css";
import { addDays, format } from "date-fns";
import matchStringType from "../utils/stringRegex";
import { ICVE, ICVEHistory, IGroupedLogs, IIPCount, ITagCount } from "../types/cves";


export interface IpData {
    client_ip: string,
    asn: string,
    country_code: string,
    count: number,
    lastAttackDate: string
}

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
                <p>{`${label}`}</p>
                <p>{`Attacks: ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};


export async function getIpLogs(ip: string) {
    const response = await processApiProxy(ip);
    return response;
}

export default function CVE() {
    const navState = useLocation();
    const navParams = useParams();
    const [loading, setLoading] = useState(false);
    const [proxyData, setProxyData] = useState<ICVE | undefined>();
    const [ipCount, setIpCount] = useState<IIPCount[] | undefined>(undefined);
    const [tagCount, setTagCount] = useState<ITagCount[] | undefined>();
    const [cveHistory, setCveHistory] = useState<ICVEHistory[] | undefined>();
    const [ipData, setIpData] = useState<IpData[]>();
    const [accordionOpenedLogs, setAccordionOpenedLogs] = useState<ICVEHistory[]>()
    const [groupedLogs, setGroupedLogs] = useState<IGroupedLogs[]>();
    const toast = useToast()



    const filterDateOptions = {
        all: { index: -1, string: "All" },
        day: { index: 1, string: "Today" },
        week: { index: 7, string: "This Week" },
        month: { index: 30, string: "This Month" },
    }
    const [filterDate, setFilterDate] = useState(filterDateOptions.all)

    useEffect(() => {

        // if (navState.state === null && navParams.searchString && !proxyData) {
        if (navParams.searchString && !proxyData) {
            processApiProxy(navParams.searchString).then((res: ICVE) => setProxyData(res));
        }
        // } else if (navState.state) {
        //     setProxyData(navState.state.response);
        // }

        if (navParams.searchString && proxyData) {
            callApiProxy("cveHistory", "POST", { data: navParams.searchString }).then((res: {logs: ICVEHistory[]}) => {
                if (!res.logs || res.logs.length === 0) {
                    setIpCount(undefined)
                    toast({
                        title: "No historical data for " + navParams.searchString,
                        status: "warning",
                        duration: 3000
                    })
                } else {
                    setTagCount(getTagTimes(res.logs))
                    setCveHistory(res.logs);
                    const tempIpCount = countByKey(res.logs as any, "remote_ip") as any;
                    console.log(tempIpCount);
                    setIpCount(tempIpCount);
                }
            })
        }
    }, [navParams.searchString]);

    useEffect(() => {
        if (!navParams.searchString) return;
        let options = "";
        const dateNow = new Date();
        if (filterDate.index !== -1) options += "&" + "fromtime=" + (format(addDays(dateNow, -filterDate.index), 'yyyy-MM-dd HH:mm:ss'));

        if (navParams.searchString && proxyData) {
            callApiProxy("cveHistory" + options, "POST", { data: navParams.searchString + options }).then((res: {logs: ICVEHistory[]}) => {
                if (res.logs.length > 0) {
                    setTagCount(getTagTimes(res.logs))
                    setCveHistory(res.logs);
                    setIpCount(countByKey(res.logs as any, "remote_ip") as any)
                } else {
                    setTagCount(undefined)
                    setCveHistory(undefined);
                    setIpCount(undefined)
                    toast({
                        title: "No results found for this time range",
                        status: "warning",
                        duration: 3000
                    })
                    setFilterDate(filterDateOptions.all)
                }
            })
        }
    }, [proxyData?.cve_id,filterDate.string])

    useEffect(() => {
        if (ipCount) {
            callApiProxy("ip-data", "POST", { data: ipCount && ipCount.map((ipObj: IIPCount) => ipObj.remote_ip) }).then((res: string) => {
                const response = JSON.parse(res) as IpData[];
                response && response.forEach((ipData, i) => {
                    const ipCountFind = ipCount.find((ip: IIPCount) => ip.remote_ip === ipData.client_ip)
                    if(!ipCountFind) return;

                    const ipDataCount = ipCountFind.count
                    response[i]["count"] = ipDataCount
                    const cveHistoryFind = cveHistory && cveHistory.find((cve: ICVEHistory) => cve.remote_ip === ipData.client_ip)
                    if(!cveHistoryFind) return;
                    response[i]["lastAttackDate"] = cveHistoryFind.time
                })
                setIpData(response);
            })
        } else {
            setIpData(undefined)
        }
    }, [ipCount])

    useEffect(() => {
        if (cveHistory && cveHistory.length > 0) {
            const groupSorted: IGroupedLogs[] = groupAndCountByDay(cveHistory).sort((a: IGroupedLogs, b: IGroupedLogs) => +new Date(a.day) - +new Date(b.day))
            setGroupedLogs(groupSorted);
        } else {
            setGroupedLogs(undefined)
        }
    }, [cveHistory]);

    if (!proxyData) return (<></>);

    function getImpactStyle(impact: string) {
        switch (impact) {
            case "HIGH":
                return { color: '#E41E20', scheme: "highImpact" }; // High warning or error color (red)
            case "MODERATE":
                return { color: '#faad14', scheme: "moderateImpace" }; // Warning color (orange)
            case "LOW":
                return { color: '#52c41a', scheme: "lowImpact" }; // Low warning color (green)
            default:
                return { color: '#d9d9d9', scheme: "default" }; // Default color (grey) for undefined or other values
        }
    }

    return (
        <div>
            <Flex width="90%" direction="column" className="cveMainDetails" backgroundImage={CVEBg}>
                <Heading as='h1' size='4xl' className={"cveId"}>{proxyData.cve_id}</Heading>
                {/* <Heading as='h1' size='2xl' color={getImpactStyle(proxyData.confidentiality_impact).color}>{proxyData.confidentiality_impact}</Heading> */}
                {/* <Divider /> */}

                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Menu>
                        <MenuButton as={Button} className="dateFilterChart" sx={{
                            margin: "6px 0px 9px 0px",
                            position: "absolute",
                            right: "30px",
                            marginRight: "30px",
                            border: "1px solid rgba(228, 30, 32, 0.39)",
                            background: "rgba(228, 30, 32, 0.21)",
                            transform: "translateY(-100%)",
                            zIndex: 1
                        }} rightIcon={<ChevronDownIcon />}>
                            {filterDate.string}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => setFilterDate(filterDateOptions.day)}>Today</MenuItem>
                            <MenuItem onClick={() => setFilterDate(filterDateOptions.week)}>Week</MenuItem>
                            <MenuItem onClick={() => setFilterDate(filterDateOptions.month)}>Month</MenuItem>
                            <MenuItem onClick={() => setFilterDate(filterDateOptions.all)}>All</MenuItem>
                        </MenuList>
                    </Menu>
                    <ResponsiveContainer width="100%" aspect={6}>
                        <AreaChart data={groupedLogs}>
                            <XAxis dataKey="day" />
                            <YAxis />
                            <Tooltip
                                label="Count"
                                cursor={false}
                                content={<CustomTooltip />}
                            />
                            <Legend />
                            <Area
                                dataKey="count"
                                stroke="rgb(228, 30, 32, 0.39)"
                                fill="rgb(228, 30, 32, 0.24)"
                            // onMouseEnter={handleMouseEnter}
                            // onMouseLeave={handleMouseLeave}
                            // shape={(props: any) => <CustomBarShape {...props} hovered={props.index === activeIndex} />}

                            >
                            </Area>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <Flex>
                    <Tabs width="81%" className="tabsWrapper" colorScheme="grey">
                        <TabList>
                            <Tab>Description</Tab>
                            <Tab>Attack Vector</Tab>
                            <Tab>Cpe Matches</Tab>
                            <Tab>CVSS Version</Tab>
                            <Tab>Source Identifier</Tab>
                            <Tab>URLs</Tab>
                            <Tab>Vector String</Tab>
                            <Tab>Vulnerability Status</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <p>{proxyData.description}</p>
                            </TabPanel>
                            <TabPanel>
                                <p>{proxyData.attack_vector}</p>
                            </TabPanel>
                            <TabPanel>
                                <p>{proxyData.cpe_matches}</p>
                            </TabPanel>
                            <TabPanel>
                                <p>{proxyData.cvss_version}</p>
                            </TabPanel>
                            <TabPanel>
                                <p>{proxyData.source_identifier}</p>
                            </TabPanel>
                            <TabPanel>
                                {proxyData.urls.map((url: string, i: number) => {
                                    return <p key={i} style={{ display: "flex", alignItems: "center", columnGap: "9px" }}><a href={url} target="_blank">{url}</a><Icon as={ImNewTab} /></p>
                                })}
                            </TabPanel>
                            <TabPanel>
                                <p>{proxyData.vector_string}</p>
                            </TabPanel>
                            <TabPanel>
                                <p>{proxyData.vulnerability_status}</p>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    <Flex width="19%" style={{ textAlign: "center" }} direction={"column"} justifyContent={"center"} className={"outerBgBox"}>
                        <span className={"innerBgBox"} style={{ height: "100%" }}>
                            <Text fontSize='3xl'>Exploitability <br /> Score <br />
                            </Text>
                            <Heading as='h1' size='2xl' color={getImpactStyle(proxyData.confidentiality_impact).color}>{proxyData.base_score}</Heading>
                        </span>
                    </Flex>
                </Flex>
                {ipCount && cveHistory && cveHistory.length > 0 &&
                    <>
                        <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                                <CustomPieChart data={ipCount} heading={"IPs Exploiting this attack"} />
                            </div>
                            {/* START INFO COMPONENT */}
                            <div className="cvePage-pies-wrapper outerBgBox" style={{ width: "45%", display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "flex-start" }}>
                                <div className="innerBgBox" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", padding: "33px" }}>
                                    <Text fontSize="4xl" color={"#E41E20"}>Tags</Text>
                                    <Text fontSize="3xl" color={""}>{tagCount && tagCount.map((tag: any) => tag.tags).join(', ')}</Text>
                                    <Text fontSize="4xl" color={"#E41E20"}>Path</Text>
                                    <Text fontSize="3xl" color={""}>{cveHistory && cveHistory[0].request_path}</Text>
                                    <Text fontSize="5xl" color={"#E41E20"} margin={"0 auto"}>TOTAL ATTEMPTS</Text>
                                    <div className="outerBgBox" style={{ padding: "12px 30px", margin: "0 auto", width: "fit-content" }}>
                                        <Text fontSize="5xl" fontWeight={"600"} letterSpacing={"9px"} color={"#E41E20"}>{cveHistory && cveHistory.length}</Text>
                                    </div>
                                </div>
                            </div>
                            {/* END INFO COMPONENT */}
                        </div>
                        {/* <div className="cvePage-treemaps-wrapper" style={{ width: "100%", display: "flex" }}>
                            <div className="cvePage-treemap-wrapper" style={{ width: "50%" }}>
                                <CustomTreeMap data={ipCount} heading={"Exploiting IPs"} />
                            </div>
                            <div className="cvePage-pies-wrapper" style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <CustomTreeMap data={tagCount} heading={"Related Tags"} />
                            </div>
                        </div> */}
                    </>
                }

                <Accordion allowToggle width={"100%"}>
                    {ipData && (ipData.sort((a, b) => +new Date(b.count) - +new Date(a.count))).map((ipObj, i) =>
                        <CustomAccordionItem key={i}
                            accordionTitle={ipObj.client_ip}
                            accordionText={`${ipObj.count} Attacks - ${ipObj.country_code} - ASN${ipObj.asn} - Latest Attack: ${ipObj.lastAttackDate}`}
                            onClick={() => {}}>
                            {cveHistory && cveHistory.length > 0 && !loading &&
                                <CustomTable
                                    headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                    rowsData={
                                        cveHistory.filter((log: ICVEHistory) => ipObj.client_ip === log.remote_ip).map((log: ICVEHistory) =>
                                            [log.time, log.request_path, log.user_agent, log.request_querystring]
                                        )
                                    } />
                            }
                        </CustomAccordionItem>
                    )}
                </Accordion>
            </Flex>
        </div>
    )
}