import { FormEvent, useRef, useState } from "react";
import processApiProxy from "../api/proxessApiProxy";
import { Button, FormControl, Input, InputGroup, InputRightElement, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import matchStringType from "../utils/stringRegex";
import { addDays, format } from "date-fns"

type SearchInputProps = {
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    pulseRef?: React.RefObject<HTMLDivElement>
};

export default function SearchInput({ search, setSearch, pulseRef }: SearchInputProps) {
    const toast = useToast()
    const navigate = useNavigate();
    const triedAllTime = useRef<boolean>(false);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        let response;
        try {
            const dateNow = new Date();
            let options = triedAllTime.current ? "" : "fromtime=" + (format(addDays(dateNow, -30), 'yyyy-MM-dd HH:mm:ss'))
            response = await processApiProxy(search, options);

            if (response && response.cve_id) {
                navigate('/cve/' + search, { replace: false, state: { response } });
            } else if (response && response.logs && response.logs.length > 0) {
                navigate('/search/' + encodeURIComponent(search), { replace: false, state: { response } });
            } else {
                if (triedAllTime.current) {
                    toast({
                        title: "No results found for this " + matchStringType(search),
                        status: "warning",
                        duration: 3000
                    })
                } else {
                    triedAllTime.current = true
                    handleSubmit(e)
                }
            }

        } catch (e: any) {
            console.log(e)
            toast({
                title: e.response.data.error ? e.response.data.error : e.code,
                status: "error",
                duration: 3000
            })
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormControl ref={pulseRef}>
                <InputGroup>
                    <Input onChange={(e) => setSearch((e.target.value))} value={search} placeholder='Search' size='lg' />
                    <InputRightElement className="input-string-indicator" style={{ height: "100%", opacity: matchStringType(search) ? 1 : 0 }}>
                        <Button type="submit">Search {matchStringType(search) || ""}</Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>
        </form>
    )
}