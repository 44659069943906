import { Icon, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { ImNewTab } from "react-icons/im"

interface CustomTableProps {
    headings: string[],
    rowsData: string[][]
}

const CustomTable = ({ headings, rowsData }: CustomTableProps) => {

    return (
        <Table variant="simple">
            <Thead>
                <Tr>
                    {headings.map((heading, i) => <Th key={i}>{heading}</Th>)}
                </Tr>
            </Thead>
            <Tbody>
                {rowsData.map((row, i) =>
                    <Tr key={i}>
                        {
                            row.map((data, i) => 
                                <Td key={i}>{data}</Td>
                            )
                        }
                    </Tr>
                )}
            </Tbody>
        </Table>
    )
}

export default CustomTable