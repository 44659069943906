import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { callApiProxy } from '../api/callApiProxy';
import ASNChart from '../components/ASNChart';
import "../assets/styles/dashboard.css";
import CountryChart from '../components/CountryChart';
import CustomPieChart from '../components/CustomPieChart';

interface ASNRes {
  asn: number;
  country_code: string;
  total_requests: number;
}

interface CVERes {
  cve: string;
  cve_count: number;
}

interface TAGRes {
  tags: string;
  tag_count: number;
}

const Dashboard = () => {
  const [asnData, setAsnData] = useState<ASNRes[]>([]);
  const [tagData, setTagData] = useState<TAGRes[]>([]);
  const [cveData, setCveData] = useState<CVERes[]>([]);

  useEffect(() => {
    callApiProxy('top-req', "POST", {data: ''})
      .then((data:string) => setAsnData(JSON.parse(data)));
    callApiProxy('top-tag', "POST", {data: ''})
      .then((data:string) => setTagData(JSON.parse(data)));
    callApiProxy('top-cve', "POST", {data: ''})
      .then((data:string) => setCveData(JSON.parse(data)));
  }, []);
  
  return (
      <Flex className="dashboardWrapper" style={{width: "90%", margin: "0 auto"}} justifyContent="space-between">
        <div style={{width: "48%"}}><ASNChart data={asnData} /></div>
        <div style={{width: "48%"}}><CountryChart data={asnData} /></div>
        <div style={{width: "48%"}}><CustomPieChart data={tagData} heading={"Top 10 Tags"}/></div>
        <div style={{width: "48%"}}><CustomPieChart data={cveData} heading={"Top 10 CVEs"} /></div>
      </Flex>
  );
};

export default Dashboard;
