import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Text } from "@chakra-ui/react";

interface DataPoint {
  asn: number;
  country_code: string;
  total_requests: number;
}

interface CountryChartProps {
  data: DataPoint[];
}

const CountryChart: React.FC<CountryChartProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleMouseEnter = (data: any, index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(-1);
  };

  const groupedData = useMemo(() => {
    const countryData: { [country: string]: number } = {};

    data.forEach((item: any) => {
      const { country_code, total_requests } = item;
      countryData[country_code] = (countryData[country_code] || 0) + total_requests;
    });

    return Object.entries(countryData)
      .map(([country_code, total_requests]) => ({ country_code, total_requests }))
      .sort((a, b) => b.total_requests - a.total_requests);
  }, [data]);

  return (
    <div className={"chartWrapper"}>
      <Text fontSize="md" sx={{ padding: "var(--chakra-space-4)", paddingBottom: "0px", color: "#5b5b5b" }}>
        Top Attacking Countries
      </Text>
      {groupedData && (
        <ResponsiveContainer className={"chartDisplay"} aspect={2.4}>
          <BarChart data={groupedData} layout="vertical">
            <XAxis type="number" dataKey="total_requests" />
            <YAxis type="category" dataKey="country_code" interval={0} />
            <Tooltip label="Count" cursor={false} content={<CustomTooltip />} />
            <Bar
              dataKey="total_requests"
              fill="rgb(228, 30, 32, 0.18)"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              shape={(props: any) => <CustomBarShape {...props} hovered={props.index === activeIndex} />}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default CountryChart;

function CustomBarShape(props: any) {
    const { x, y, width, height, hovered } = props;
    // Adjust fill color based on hover state
    const fillColor = hovered ? "rgba(228, 30, 32, 0.39)" : "rgba(228, 30, 32, 0.18)";

    return (
        <g>
            {/* Main bar shape */}
            <rect x={x} y={y} width={width} height={height} fill={fillColor} />
            {/* Top border */}
            <path d={`M${x} ${y} h${width}`} stroke="red" strokeWidth={0.5} />
            {/* Right border */}
            <path d={`M${x + width} ${y} v${height}`} stroke="red" strokeWidth={0.5} />
            {/* Left border */}
            <path d={`M${x} ${y} v${height}`} stroke="red" strokeWidth={0} />
            {/* Bottom border */}
            <path d={`M${x} ${y + height} h${width}`} stroke="red" strokeWidth={0.5} />
        </g>
    );
}


const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
                <p>{`${label}`}</p>
                <p>{`Attacks: ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};