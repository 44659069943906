import matchStringType from "../utils/stringRegex";
import { callApiProxy } from "./callApiProxy";

export default async function processApiProxy(search: string, options?: string) {
    options = options ? options : "";
    try {
        search = decodeURIComponent(search);
        switch (matchStringType(search)) {
            case "CVE":
                return await callApiProxy("cve", "POST", { data: search + (options ? "?" : "") + options });
            case "IPv4":
                return await callApiProxy("ip", "POST", { data: search + (options ? "?" : "") + options });
            case "UnixPath" || "WindowsPath":
                return await callApiProxy("path", "POST", { data: search + (options ? "&" : "") + options });
            case "Tag":
                return await callApiProxy("tags", "POST", { data: search + (options ? "&" : "") + options });
        }
        return undefined;
    } catch (e: any) {
        throw e;
    }
}