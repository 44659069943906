import React, { useRef, useState } from "react";
import { Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useAuthStore } from "../store/useAuthStore"; // Adjust the import path to your Zustand store

interface LoginProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function Login({ open, setOpen }: LoginProps) {
    const initialRef = useRef<HTMLInputElement>(null);
    const [email, setEmail] = useState(''); // Use email as the identifier for login
    const [password, setPassword] = useState('');
    const login = useAuthStore((state) => state.login); // Access the login function from the store

    const handleLogin = async (e: any, prevent = false) => {
        if(prevent) {
            e.preventDefault()
        }
        try {
            await login(email, password);
            setOpen(false); // Close the modal on successful login
        } catch (error) {
            console.error("Login error:", error);
            // Optionally, handle/display error feedback to the user
        }
    };

    return (
        <Modal isOpen={open} initialFocusRef={initialRef} onClose={() => setOpen(false)} isCentered={true}>
            <ModalOverlay />
            <ModalContent className="modal-content">
                <ModalHeader>Log In</ModalHeader>
                <ModalCloseButton />
                <FormControl>
                    <form onSubmit={(e)=>handleLogin(e, true)}>
                        <ModalBody>
                            <Input
                                ref={initialRef}
                                placeholder='Email'
                                size='lg'
                                marginBottom="3"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Input
                                placeholder='Password'
                                type="password"
                                size='lg'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </ModalBody>

                        <ModalFooter>
                            <Button mr={3} onClick={handleLogin} type="submit">
                                Log In
                            </Button>
                            <Button mr={3} onClick={() => setOpen(false)}>
                                Close
                            </Button>
                        </ModalFooter>
                    </form>
                </FormControl>
            </ModalContent>
        </Modal >
    );
}
