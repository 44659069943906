import { Accordion, AccordionButton, AccordionButtonProps, AccordionIcon, AccordionItem, AccordionItemProps, AccordionPanel, Flex, Progress, Text } from "@chakra-ui/react"
import CustomTable from "./CustomTable"
import { MouseEventHandler, ReactNode } from "react"

interface CustomAccordionItemProps extends AccordionButtonProps {
    accordionTitle: string,
    accordionText: string,
    children: ReactNode,
    loading?: boolean,
    onClick?: MouseEventHandler
}

const CustomAccordionItem = ({ accordionTitle, accordionText, children, onClick, loading = false }: CustomAccordionItemProps) => {

    const handleTitleClick: MouseEventHandler<Element> = (e) => {
        const classList: DOMTokenList = (e.target as any).classList
        const isTitle = classList.contains("custom-accordion-title")
        if(onClick && !isTitle) {
            onClick(e)
        }
    }

    return (
        <AccordionItem className="outerBgBox">
            <h2>
                <AccordionButton columnGap={"6px"} onClick={handleTitleClick}>
                    <AccordionIcon />
                    <Flex flex='1' textAlign='left' whiteSpace={"pre"}>
                        <Text
                        onClick={(e)=> e.preventDefault()}
                        fontSize="xl"
                        color={"#E41E20"}
                        className="custom-accordion-title"
                        style={{userSelect: "text", cursor: "text"}}>{accordionTitle}</Text>
                        <Text> - </Text>
                        <Text fontSize="md">{accordionText}</Text>
                    </Flex>
                </AccordionButton>
            </h2>
            {!loading &&
                <AccordionPanel pb={4} className="innerBgBox" style={{ width: "auto", boxSizing: "border-box", overflowX: "auto" }}>
                    {children}
                </AccordionPanel>
            }
            {loading &&
                <AccordionPanel pb={4} className="innerBgBox" style={{ width: "auto", boxSizing: "border-box", overflowX: "auto", marginTop: "0", padding: "0" }}>
                    <Progress size='xs' isIndeterminate colorScheme={"red"} sx={{"[role=progressbar]": {backgroundImage: "linear-gradient(to right, transparent 0%, rgba(228, 30, 32, 0.9) 50%, transparent 100%)"}}}/>
                </AccordionPanel>
            }
        </AccordionItem >
    )
}

export default CustomAccordionItem