import { IGroupedLogs, ITagCount } from "../types/cves";

interface ObjectWithKey<T> {
  [key: string]: T;
}

function countByKey<T>(data: ObjectWithKey<T>[], key: keyof ObjectWithKey<T>): { [key: string]: T | number }[] {
  return data.reduce((accumulator: { [key: string]: T | number }[], currentValue: ObjectWithKey<T>) => {
    const keyValue = currentValue[key];
    const existingItem = accumulator.find((item) => item[key as string] === keyValue);

    if (existingItem) {
      existingItem.count = (existingItem.count as number) + 1;
    } else {
      accumulator.push({ [key]: keyValue, count: 1 });
    }

    return accumulator;
  }, []);
}

export default countByKey

export function getTagTimes(logs: any[]): ITagCount[] {
  const tagCounts: { [tag: string]: number } = {};

  // Iterate through the logs and sum up the request_timetaken for each tag
  logs.forEach(log => {
    const tags = log.tags;
    const requestTime = log.request_timetaken;

    tags.forEach((tag: string) => {
      if (tagCounts[tag]) {
        tagCounts[tag] += requestTime;
      } else {
        tagCounts[tag] = requestTime;
      }
    });
  });

  // Convert the tagCounts object into an array of ITagCount objects
  const result: ITagCount[] = Object.entries(tagCounts).map(([tags, count]) => ({
    tags,
    count,
  }));

  return result;
}

export const groupAndCountByDay = (array: any[]): IGroupedLogs[] => {
  const grouped: { [key: string]: number } = array.reduce((acc, obj) => {
    const date = obj.time.split(' ')[0];
    if (!acc[date]) {
      acc[date] = 1;
    } else {
      acc[date]++;
    }
    return acc;
  }, {});

  return Object.entries(grouped).map(([day, count]) => ({
    day,
    count,
  }));
};