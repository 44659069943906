import {
  Flex,
  Heading,
  Image
} from '@chakra-ui/react';
import Logo from "../assets/sphere.logo.x120.png";
import "../assets/styles/navbar.css";
import Login from './Login';
import { useState } from 'react';
import Signup from './Signup';
import CustomButton from './buttons/CustomButton';
import { useAuthStore } from '../store/useAuthStore';
import { Link, useLocation } from 'react-router-dom';
import SearchInput from './SearchInput';

export default function Navbar() {
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const [search, setSearch] = useState<string>("");
  const navState = useLocation();

  return (
    <Flex justifyContent={"center"} flexDir={"column"} alignItems={"center"}>
      <Flex className="nav-wrapper" justifyContent={"space-between"}>
        <Login open={loginModal} setOpen={setLoginModal} />
        <Signup open={signupModal} setOpen={setSignupModal} />

        <Flex columnGap={"24px"}>
          <Link to="/"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Home</Heading></Link>
          <Link to="/latest"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Latest</Heading></Link>
          {/* <Heading className={"nav-heading"} size="sm" fontWeight={400}>Threats</Heading> */}
        </Flex>

        <Flex pl="6%" className={"trends"}>
          <Link to="/"><Image minWidth="34px" style={{ width: "60px", height: "60px" }} src={Logo} /></Link>
        </Flex>

        <Flex className={"buttons"}>
          <CustomButton display={isAuthenticated ? "none" : "block"} onClick={() => setLoginModal(true)} variant={"black"}>LOGIN</CustomButton>
          <CustomButton display={isAuthenticated ? "none" : "block"} onClick={() => setLoginModal(true)} variant={"red"}>SIGN UP</CustomButton>
          <CustomButton display={isAuthenticated ? "block" : "none"} variant="red"><Link to="/dashboard">DASHBOARD</Link></CustomButton>
        </Flex>
      </Flex>
      {navState.pathname !== '/' &&
        <Flex width="100%" justifyContent={"center"}><div style={{ marginRight: "12px", width: "60%" }}><SearchInput search={search} setSearch={setSearch} /></div></Flex>
      }
    </Flex>
  );
}