import { useEffect, useState } from "react";
import { callApiProxy } from "../api/callApiProxy";
import { Flex, Heading, Text } from "@chakra-ui/react";
import CustomPieChart from "../components/CustomPieChart";

interface LatestTags {
    latest_time: string;
    request_count: number;
    tags: string[]
}
interface LatestCves {
    latest_time: string;
    request_count: number;
    cve: string
}

export default function Search() {
    const [latestTags, setLatestTags] = useState<LatestTags[]>()
    const [latestCves, setLatestCves] = useState<LatestCves[]>()
    useEffect(() => {
        callApiProxy('latest-tags', "POST", { data: '' })
            .then((data: string) => setLatestTags(
                JSON.parse(data).sort((a: LatestTags, b: LatestTags) => a.request_count - b.request_count)
            ));
            
        callApiProxy('latest-cves', "POST", { data: '' })
        .then((data: string) => setLatestCves(
            JSON.parse(data).sort((a: LatestCves, b: LatestCves) => a.request_count - b.request_count)
        ));
    }, []);

    if ((!latestTags || latestTags.length === 0) || (!latestCves || latestCves.length === 0)) return <></>
    return (
        <Flex w="90%" flexDir={"column"} margin="0 auto" rowGap={30} mt={30}>
            <Heading as='h1' size='4xl' className={"cveId"} textAlign={"center"}>LATEST ATTACKS</Heading>
            <Flex>
                <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                        <CustomPieChart data={latestCves} heading={"Latest CVEs From Attacks"} />
                    </div>
                    <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                        <CustomPieChart data={latestTags} heading={"Latest Tags From Attacks"} />
                    </div>
                </div>
            </Flex>
        </Flex>
    )
}