import { create } from 'zustand';
import { supabase } from '../api/supabase'; // Ensure this path is correct

interface AuthState {
    isAuthenticated: boolean;
    loading: boolean; // Added to track session loading state
    user: any | null;
    checkSession: () => Promise<void>;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
  }

export const useAuthStore = create<AuthState>((set) => ({
    isAuthenticated: false,
    loading: true, // Initially true until the session check completes
    user: null,
    checkSession: async () => {
        try {
            const { data } = await supabase.auth.getSession();
            set({ isAuthenticated: !!data.session?.user, user: data.session?.user ?? null, loading: false });
        } catch (error) {
            console.error("Session check error:", error);
            set({ loading: false }); // Ensure loading is set to false even if there's an error
        }
    },
    login: async (email: string, password: string) => {
        const { error, data: user } = await supabase.auth.signInWithPassword({ email, password });
        if (error) {
            alert("Login Failed");
            throw error;
        }
        set({ isAuthenticated: !!user });
    },
    logout: async () => {
        const { error } = await supabase.auth.signOut();
        if (error) throw error;
        set({ isAuthenticated: false, user: null });
    },
}));
