import { Button, FormControl, Input, InputGroup, InputRightElement, Text, useToast } from "@chakra-ui/react";
import bgLogo from "../assets/Isolation_Mode.svg"
import "../assets/styles/home.css";
import sphLogo from "../assets/logo-big.svg";
import { FormEvent, useRef, useState } from "react";
import matchStringType from "../utils/stringRegex";
import { useNavigate } from "react-router-dom";
import processApiProxy from "../api/proxessApiProxy";
import "../assets/styles/pulseeffect.css";
import SearchInput from "../components/SearchInput";

export default function Home() {
    const [search, setSearch] = useState<string>("");
    const pulseRef = useRef<HTMLDivElement>(null);

    const pulse = () => {
        if (pulseRef.current) {
            pulseRef.current.classList.remove('pulse');
            setTimeout(()=>pulseRef.current?.classList.add('pulse'), 0); /// Move execution to the next tick
        }
    }

    return (
        <div style={{ width: "100%", background: `url(${bgLogo})`, backgroundRepeat: "no-repeat", backgroundPosition: "right" }}>
            <div className="content-wrapper">
                {/* <img src={sphLogo} style={{ margin: "0 auto" }} alt="" /> */}
                <SearchInput search={search} setSearch={setSearch} pulseRef={pulseRef} />
                <div className="suggestions-wrapper">
                    <div className="suggestion-wrapper" onClick={()=> {setSearch("185.224.128.10"); pulse()}}><Text fontSize='md'>Search By IP</Text><div>&gt;</div></div>
                    <div className="suggestion-wrapper" onClick={()=> {setSearch("CVE-2023-1389"); pulse()}}><Text fontSize='md'>Search By CVE</Text><div>&gt;</div></div>
                    <div className="suggestion-wrapper" onClick={()=> {setSearch("/cgi-bin/luci/;stok=/locale"); pulse()}}><Text fontSize='md'>Search By Path</Text><div>&gt;</div></div>
                    <div className="suggestion-wrapper" onClick={()=> {setSearch("Fortinet"); pulse()}}><Text fontSize='md'>Search by Vendor</Text><div>&gt;</div></div>
                </div>
            </div>
        </div>
    );
}